<template>
  <div class="truck-list-icon">
    <div class="truck-status truck-runing">
      <div class="truck-info">
        <!-- <span>{{ $t('car.work.data.title') }}</span> -->
        <span>在线中车辆</span>
        <span>{{ carNumber1 }}辆</span>
      </div>
      <a-icon :component="truckRuning"/>
    </div>
    <div class="truck-status truck-charge">
      <div class="truck-info">
        <!-- <span>{{ $t('car.charging.data.title') }}</span> -->
        <span>空闲中车辆</span>
        <span>{{ carNumber2 }}辆</span>
      </div>
      <a-icon :component="truckCharge"/>
    </div>
    <div class="truck-status truck-standby">
      <div class="truck-info">
        <!-- <span>{{ $t('car.standby.data.title') }}</span> -->
        <span>任务中车辆</span>
        <span>{{ carNumber3 }}辆</span>
      </div>
      <a-icon :component="truckStandby"/>
    </div>
    <div class="truck-status truck-maintain">
      <div class="truck-info">
        <!-- <span>{{ $t('car.repair.data.title') }}</span> -->
        <span>托管中车辆</span>
        <span>{{ carNumber4 }}辆</span>
      </div>
      <a-icon :component="truckMaintain"/>
    </div>
    <div class="truck-status truck-undefined">
      <div class="truck-info">
        <!-- <span>{{ $t('car.undefined.data.title') }}</span> -->
        <span>离线车辆</span>
        <span>{{ carNumber5 }}辆</span>
      </div>
      <a-icon :component="truckUndefined"/>
    </div>
    <!-- <div class="truck-status truck-runing">
      <div class="truck-info">
        <span>{{ $t('car.work.data.title') }}</span>
        <span>{{ carNumber2 }}辆</span>
      </div>
      <a-icon :component="truckRuning"/>
    </div>
    <div class="truck-status truck-charge">
      <div class="truck-info">
        <span>{{ $t('car.charging.data.title') }}</span>
        <span>{{ carNumber3 }}辆</span>
      </div>
      <a-icon :component="truckCharge"/>
    </div>
    <div class="truck-status truck-standby">
      <div class="truck-info">
        <span>{{ $t('car.standby.data.title') }}</span>
        <span>{{ carNumber1 }}辆</span>
      </div>
      <a-icon :component="truckStandby"/>
    </div>
    <div class="truck-status truck-maintain">
      <div class="truck-info">
        <span>{{ $t('car.repair.data.title') }}</span>
        <span>{{ carNumber4 }}辆</span>
      </div>
      <a-icon :component="truckMaintain"/>
    </div>
    <div class="truck-status truck-undefined">
      <div class="truck-info">
        <span>{{ $t('car.undefined.data.title') }}</span>
        <span>{{ carNumber5 }}辆</span>
      </div>
      <a-icon :component="truckUndefined"/>
    </div> -->
  </div>
</template>

<script>
import truckCharge from '@/assets/icons/vehicle_charge.svg?inline'
import truckMaintain from '@/assets/icons/vehicle_maintain.svg?inline'
import truckRuning from '@/assets/icons/vehicle_working.svg?inline'
import truckStandby from '@/assets/icons/vehicle_standby.svg?inline'
import truckUndefined from '@/assets/icons/vehicle_undefined.svg?inline'

export default {
  name: 'TruckIconList',
  props: {
    carNumber1: {
      type: Number,
      default: 0
    },
    carNumber2: {
      type: Number,
      default: 0
    },
    carNumber3: {
      type: Number,
      default: 0
    },
    carNumber4: {
      type: Number,
      default: 0
    },
    carNumber5: {
      type: Number,
      default: 0
    }
  },
  computed: {
    num1 () {
      return this.carNumber1
    },
    num2 () {
      return this.carNumber2
    },
    num3 () {
      return this.carNumber3
    },
    num4 () {
      return this.carNumber4
    },
    num5 () {
      return this.carNumber5
    }
  },
  data () {
    return {
      truckCharge,
      truckMaintain,
      truckRuning,
      truckStandby,
      truckUndefined
    }
  }
}
</script>

<style scoped lang="less">
.truck-list-icon {
  display: flex;
}
.truck-status {
  color: #FFF;
  overflow: hidden;
  border-radius: 10px;
  height: 80px;
  width: 20%;
  i {
    height: 80px;
    margin-top: -20px;
    margin-bottom: 20px;
    font-size: 120px;
    float: right;
  }
  .truck-info {
    float: left;
    overflow: hidden;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-left: 20px;
    span {
      font-weight: 600;
    }
    span:last-child {
      width: 100%;
      font-size: 18px;
      text-align: left;
      margin-top: 5px;
    }
  }
}
.truck-runing {
  margin-right: 10px;
  background-image: linear-gradient(to right, #746dff, #539ddc);
}
.truck-charge {
  margin: 0 10px;
  background-image: linear-gradient(to right, #ee8d31, #e9c100);
}
.truck-standby {
  margin: 0 10px;
  background-image: linear-gradient(to right, #00c0af, #41bc32);
}
.truck-maintain {
  margin: 0 10px;
  background-image: linear-gradient(to right, #e5453e, #c068cb);
}
.truck-undefined {
  margin-left: 10px;
  background-image: linear-gradient(to right, #873de3, #d895f3);
}
</style>
