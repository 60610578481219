var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "truck-list-icon" }, [
    _c(
      "div",
      { staticClass: "truck-status truck-runing" },
      [
        _c("div", { staticClass: "truck-info" }, [
          _c("span", [_vm._v("在线中车辆")]),
          _c("span", [_vm._v(_vm._s(_vm.carNumber1) + "辆")]),
        ]),
        _c("a-icon", { attrs: { component: _vm.truckRuning } }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "truck-status truck-charge" },
      [
        _c("div", { staticClass: "truck-info" }, [
          _c("span", [_vm._v("空闲中车辆")]),
          _c("span", [_vm._v(_vm._s(_vm.carNumber2) + "辆")]),
        ]),
        _c("a-icon", { attrs: { component: _vm.truckCharge } }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "truck-status truck-standby" },
      [
        _c("div", { staticClass: "truck-info" }, [
          _c("span", [_vm._v("任务中车辆")]),
          _c("span", [_vm._v(_vm._s(_vm.carNumber3) + "辆")]),
        ]),
        _c("a-icon", { attrs: { component: _vm.truckStandby } }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "truck-status truck-maintain" },
      [
        _c("div", { staticClass: "truck-info" }, [
          _c("span", [_vm._v("托管中车辆")]),
          _c("span", [_vm._v(_vm._s(_vm.carNumber4) + "辆")]),
        ]),
        _c("a-icon", { attrs: { component: _vm.truckMaintain } }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "truck-status truck-undefined" },
      [
        _c("div", { staticClass: "truck-info" }, [
          _c("span", [_vm._v("离线车辆")]),
          _c("span", [_vm._v(_vm._s(_vm.carNumber5) + "辆")]),
        ]),
        _c("a-icon", { attrs: { component: _vm.truckUndefined } }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }